















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Filters from '@/components/Filters.vue';
import SearchBar from '@/components/SearchBar.vue';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import { ICourses, ICourseSingle } from '@/types/courses';
import { coursesLists } from '@/service/api/module/course';
import CoursesCard from '@/components/CoursesCard.vue';
import { MetaInfo } from 'vue-meta';
import Button from '@/components/Button.vue';
import { IEventsFilters } from '@/types/events';
import Loading from '@/components/Loading.vue';
import vueDebounce from 'vue-debounce';

Vue.use(vueDebounce);

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.search.titleSeo').toString(),
    };
  },
  components: {
    Filters,
    CoursesCard,
    SearchBar,
    Button,
    Loading,
  },
})
export default class Search extends Vue {
  @Prop({ required: true, default: null }) search!: string;

  public courses: ICourseSingle[] | null = [];
  public coursesLimit = 20;
  public coursesOffset = 20;
  public searchText = '';
  public searchTextLimit = false;
  public searchOptions: ICourseSingle[] | null = [];
  public isLoadingNext = false;
  public isNextButtons = false;
  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;
  public filters?: IEventsFilters | null = null;
  public isLoading = true;

  private async created() {
    const courses = await coursesLists();
    if (courses.items.length) {
      this.courses = courses?.items;
      this.isLoading = false;
    }
    this.isNextButtons = this.courses?.length === this.coursesLimit ? true : false;
    this.filters = courses.filters;

    if (this.search) {
      this.searchText = this.search || '';
      setTimeout(async () => await this.onSearch(), 500);
    }
  }

  private submitObjectFilters(courses: ICourseSingle[] | null) {
    this.courses = courses;
    this.isNextButtons = courses ? (courses.length === this.coursesOffset ? true : false) : false;
  }

  public async onSearch() {
    if (this.searchText.length <= 3 && this.searchText.length !== 0) {
      this.searchTextLimit = true;
    } else {
      this.searchTextLimit = false;
    }

    const filters = this.$refs.filtersComponent as any;
    filters.onSearchText();
  }

  public onSearchEmpty() {
    if (this.searchText.length === 0) {
      this.onSearch();
    }
  }

  public async loadMore() {
    if (this.isLoadingNext || !this.courses || !this.isNextButtons) {
      return;
    }

    this.isLoadingNext = true;
    const courses: ICourses | null = await coursesLists(this.coursesLimit);
    if (courses) {
      this.courses.push(...courses.items);
      this.isNextButtons = courses.meta.total_count === this.coursesOffset ? true : false;
      this.coursesLimit = this.coursesLimit + this.coursesOffset;
    }
    this.isLoadingNext = false;
  }
}
